/* Import ------------------------------------------- */
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

/* Global ------------------------------------------- */
*,
body {
  font-family: $font-family-base;
  letter-spacing: $letter-spacing;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  display: block;

  &.divider {
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      max-width: 100%;
      width: 100%;
      height: 1px;
      margin: 1em 0;
      border-top: 1px solid lightgrey;
    }
  }
  span,
  .section {
    color: $brand-blue !important;
  }
  .section {
    padding: 0;
  }
}

h1,
.h1 {
  font-size: $font-size-h1;
}

h2,
.h2 {
  font-size: $font-size-h2;
}

h3,
.h3 {
  font-size: $font-size-h3;
}

h4,
.h4 {
  font-size: $font-size-h4;
}

h5,
.h5 {
  font-size: $font-size-h5;
}

h6,
.h6 {
  font-size: $font-size-h6;
}
p {
  @include rem-fallback(font-size, 0.9);
}
.lead {
  font-size: $font-size-base + .3;
  font-weight: 300;
}
.legal{
  font-size: $font-size-base - .3 !important;
}
.muted{
  color: transparentize($body-color, 0.5) !important;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

/* Conditional sections ----------------------------- */
.sidebar {
  h1,
  .h1 {
    font-size: ($font-size-base * 2);
  }
  h2,
  .h2 {
    font-size: ($font-size-base * 1.65);
  }
  h3,
  .h3 {
    font-size: ($font-size-base * 1);
  }
  dl {
    font-size: $font-size-base - 0.25;

    //This is reversed from the default values
    dt {
      font-weight: 100;
    }
    dd {
      font-weight: 700;
    }
  }
}
pre {
  display: block;
  width: 100%;
  margin: 1em 0;
  padding: 1em;
  background-color: #f7f7f9;
  color: #212529;
}
code {
  font-size: inherit;
  font-weight: lighter;
  padding: 0 0.25em;
  background-color: #444;
  color: white;
}
/*
  Typography.
  This file controls general typography.
-------------------------------------------------- */

/*
  Mixins are taken from Zeplin, placed here, and slightly modified.
  - remove font-family (redundant)
-------------------------------------------------- */
@mixin Patient-Name-Text {
  font-size: 24px;
  color: $gray-darker;
  letter-spacing: -0.5px;
}

@mixin Large-Title-Text {
  font-size: 24px;
  font-weight: 300;
  color: $gray-darker;
  letter-spacing: -0.5px;
}

@mixin Question-Text {
  font-size: 16px;
  line-height: 1.25;
  color: $black;
  letter-spacing: -0.3px;
}

@mixin Careform-Footer-Contact-Text {
  font-size: 14px;
  color: $gray-darker;
  letter-spacing: -0.3px;
}

@mixin Details-Section-Title {
  font-size: 13px;
  font-weight: 600;
  color: $gray-darker;
  text-transform: uppercase;
  letter-spacing: -0.3px;
}

@mixin Current-Timeline-Step-Text {
  font-size: 13px;
  font-weight: 600;
  color: $black;
  letter-spacing: -0.3px;
}

@mixin Link-Text {
  font-size: 13px;
  font-weight: 600;
  color: $dodger-blue;
  letter-spacing: -0.3px;
}

@mixin Body-Title-Text {
  font-size: 13px;
  color: $gray-darker;
  letter-spacing: -0.3px;
}

@mixin Body-Text {
  font-size: 13px;
  color: $gray-darker;
  letter-spacing: -0.2px;
}

@mixin Attachment-File-Text {
  font-size: 12px;
  color: $gray-darker;
  letter-spacing: -0.3px;
}

b,
strong {
  font-weight: 700;
}
