.notify {
  padding: 0 $pad-data;
  width: 100%;

  .notify__item {
    display: block;
    width: 100%;
    position: relative;
    padding: 0.5rem 1rem !important;
    margin: 1rem 0;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    .mdi-icon {
      display: inline-block;
      width: 42px;
      height: 50px;
      padding: 0.125rem;
      color: inherit;
      border-radius: 50%;
      margin-right: 1em;

      &.help-icon {
        padding: 0.375rem 0;
        height: 42px;
      }
    }
    p {
      margin: 0;
    }

    .content {
      display: inline-block;
      width: 85%;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        margin-bottom: 0;
        padding: 0;
      }
    }
    .header {
      font-weight: 700;
    }

    &--default, &--static {
      background-color: $soft-purple;
      border: 1px solid $bright-purple;
      color: $brand-purple;
    }

    &--aware {
      background-color: lighten($status-2, 20%);
      border: 1px solid lighten($status-2, 10%);
      color: darken($status-2, 30%);
    }

    &--submitted {
      background-color: lighten($dodger-blue, 20%);
      border: 1px solid lighten($dodger-blue, 10%);
      color: darken($dodger-blue, 30%);
    }

    &--approved {
      background-color: lighten($status-3, 20%);
      border: 1px solid lighten($status-3, 10%);
      color: darken($status-3, 30%);
    }

    &--action,
    &--denied {
      background-color: lighten($status-1, 20%);
      border: 1px solid lighten($status-1, 10%);
      color: darken($status-1, 30%);
    }
    &--action,&--denied,&--approved,&--submitted,&--aware,&--default,&--static{
      p{
        color: inherit;
      }
    }
  }
}
.collapse {
  .notify {
    .notify__item {
      position: relative;
      .mdi-icon {
        width: 42px;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translate(0%, -50%);
      }
      .content {
        margin-left: 40px;
        padding: 0.5em 0;
        h5 {
          font-size: 14px;
        }
      }
      @media screen and (max-width: $break-small) {
        .mdi-icon {
          width: 42px;
        }
        .content {
          width: 200px;
          h5 {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.notify-small {
  position: relative;
  padding: 0 $pad-data;
  width: 100%;
  color: inherit;

  .notify__item {
    display: block;
    width: 100%;
    position: relative;
    padding: 0.25rem 0.5rem !important;
    margin: .5rem 0;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    overflow: auto;
    color: inherit;

    .mdi-icon {
      display: table-cell;//inline-block;
      width: 16px;
      height: 16px;
      padding: 0;
      vertical-align: top;
      color: inherit;
      border-radius: 50%;
      margin-top: $pad-data - 10;
      margin-right: 0.5em;
      background-color: inherit;
      position: relative;
      float: left;
    }
    p {
      font-size: 0.8em;
      margin: 0;
      color: inherit;
      padding: 0;
    }
    .content {
      display: table-cell;//inline-block;
      width: auto;
      margin-left: 0;
    }

    &--static, &--default {
      background-color: $soft-purple;
      border: 1px solid $bright-purple;
      color: $brand-purple !important;
      p{color: inherit;}

      .mdi-icon {
        background-color: $brand-purple;
        color: $soft-purple;
      }
    }

    &--aware {
      background-color: lighten($status-2, 20%);
      border: 1px solid lighten($status-2, 5%);
      color: darken($status-2, 30%) !important;
      p{color: inherit;}

      .mdi-icon {
        background-color: darken($status-2, 30%);
        color: lighten($status-2, 20%) !important;
      }
    }

    &--submitted {
      background-color: lighten($dodger-blue, 20%);
      border: 1px solid lighten($dodger-blue, 10%);
      color: darken($dodger-blue, 30%) !important;
      p{color: inherit;}

      .mdi-icon {
        background-color: darken($dodger-blue, 30%);
        color: lighten($dodger-blue, 20%) !important;
      }
    }

    &--approved {
      background-color: lighten($status-3, 20%);
      border: 1px solid lighten($status-3, 0%);
      color: darken($status-3, 30%) !important;
      p{color: inherit;}

      .mdi-icon {
        background-color: darken($status-3, 30%);
        color: lighten($status-3, 20%) !important;
      }
    }

    &--action,
    &--denied {
      background-color: lighten($status-1, 20%);
      border: 1px solid lighten($status-1, 0%);
      color: darken($status-1, 30%) !important;
      p{color: inherit;}

      .mdi-icon {
        background-color: darken($status-1, 30%);
        color: lighten($status-1, 20%);
      }
    }
  }
}
