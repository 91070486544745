.bubble-train {
  position: relative;
  display: flex;
  margin: 2rem -18px 3rem;
  overflow: hidden;
  width: 100%;

  &::before {
    position: absolute;
    top: 2.5rem;
    right: 0;
    left: 0;
    z-index: 1;
    content: '';
    border-top: 1px solid project-color('gray-light');

    @include media-breakpoint-down(lg) {
      width: auto;
    }
  }
}

.bubble__item {
  position: relative;
  z-index: 2;
  width: 25%;
  padding: 0 1.25rem;
  text-align: center;

  &:last-child {
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      width: 1000px;
      content: '';
      background: project-color('white');
      transform: translateX(15%);
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      content: '';
      background: project-color('white');
      transform: translateX(50%);
    }
  }
  &__type {
    font-size: 0.8rem;
    font-weight: 500;
    margin: auto;
    width: 100%;
    white-space: nowrap;
  }
  &__label {
    font-size: 0.75rem;
  }
  &__small-label {
    font-size: 0.75rem;
  }

  .mdi-icon {
    width: 42px;
    padding: 0.125rem;
    color: project-color('white');
    background: project-color('blueberry');
    border: 4px solid project-color('white');
    border-radius: 50%;

    &.help-icon {
      padding: 0.3rem 0;
      height: 42px;
    }
  }

  &--default {
    .mdi-icon {
      background: project-color('grey');
    }
  }
  &--submitted {
    .mdi-icon {
      background: project-color('nimbus-blue');
    }
  }
  &--approved {
    .mdi-icon {
      background: project-color('aqua-marine');
    }
  }

  &--processing {
    .mdi-icon {
      background: project-color('apricot');
    }
  }

  &--denied {
    .mdi-icon {
      background: project-color('pale-salmon');
    }
  }

  &--aware {
    .mdi-icon {
      background: $status-2;
    }
  }
}
