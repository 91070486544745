//used for layout and spacing NOT type or color
.sidebar {
  padding: 0 1.5em;

  dt,
  dd {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }
}
