.verify-radio {
  position: relative;
  border-radius: 4px;
  border: 1px solid #d8d8d8;

  &:hover {
    border: 1px solid #4f94fb;
  }

  input[type='checkbox'] {
    &:checked + .verify-radio {
      border: 1px solid #4f94fb;
    }
  }
}
input[type='radio']:checked ~ .verify-radio {
  border: 1px solid red;
}
input[type='radio']:checked {
  border: 1px solid red;
}
