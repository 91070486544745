.section-header {
  &:before {
    content: '';
    z-index: 1;

    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    width: 100%;
    height: 1px;

    background: #dddddd;
  }
}

.section-header__title {
  width: 99%;
  position: relative;
  z-index: 2;
  border-bottom: 3px solid;
  padding: 5px 0px;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  margin: 0 auto 0.5em auto;

  &.default {
    color: $bright-purple;
    border-color: $bright-purple;
  }
  &.status-1 {
    color: $status-1;
    border-color: $status-1;
  }
  &.status-2 {
    color: $status-2;
    border-color: $status-2;
  }
  &.status-3 {
    color: $status-3;
    border-color: $status-3;
  }
}
