/*
  Typography.
  This file controls general typography.
-------------------------------------------------- */

/*
  Mixins are taken from Zeplin, placed here, and slightly modified.
  - remove font-family (redundant)
-------------------------------------------------- */
@mixin Patient-Name-Text {
  font-size: 24px;
  color: $gray-darker;
  letter-spacing: -0.5px;
}

@mixin Large-Title-Text {
  font-size: 24px;
  font-weight: 300;
  color: $gray-darker;
  letter-spacing: -0.5px;
}

@mixin Question-Text {
  font-size: 16px;
  line-height: 1.25;
  color: $black;
  letter-spacing: -0.3px;
}

@mixin Careform-Footer-Contact-Text {
  font-size: 14px;
  color: $gray-darker;
  letter-spacing: -0.3px;
}

@mixin Details-Section-Title {
  font-size: 13px;
  font-weight: 600;
  color: $gray-darker;
  text-transform: uppercase;
  letter-spacing: -0.3px;
}

@mixin Current-Timeline-Step-Text {
  font-size: 13px;
  font-weight: 600;
  color: $black;
  letter-spacing: -0.3px;
}

@mixin Link-Text {
  font-size: 13px;
  font-weight: 600;
  color: $dodger-blue;
  letter-spacing: -0.3px;
}

@mixin Body-Title-Text {
  font-size: 13px;
  color: $gray-darker;
  letter-spacing: -0.3px;
}

@mixin Body-Text {
  font-size: 13px;
  color: $gray-darker;
  letter-spacing: -0.2px;
}

@mixin Attachment-File-Text {
  font-size: 12px;
  color: $gray-darker;
  letter-spacing: -0.3px;
}

b,
strong {
  font-weight: 700;
}
