%wizard-headerText {
  color: $slate-grey;
  padding: 0 15px;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}
form {
  h1 {
    display: block;
    width: 100%;
  }
}

label {
  display: block;
}
select {
  // TODO: add selector for PHONE type
  //min-width: 300px;
}
input [type='text'] {
  width: 100%;
}
small {
  color: grey;
}

input {
  border-radius: 3px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
}

.wizard,
.form {
  padding: 1em;
  color: $slate-grey;
  h1,
  div + h1 {
    @extend %wizard-headerText;
    font-size: $font-size-base * 1.25;
    line-height: 1em;
    margin: 0 0 1em;

    span {
      color: $brand-blue;
    }

    &::after {
      // full-width divider
      content: '';
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      height: 1px;
      margin: 1em 0;
      border-top: 1px solid lightgrey;
    }
  }
  p {
    @extend %wizard-headerText;
    font-size: $font-size-base * 0.85;
  }
  .verifyPatient {
    div {
      @extend %wizard-headerText;
      font-size: $font-size-base * 0.75;
      font-weight: bold;
    }
  }
  hr {
    padding: 20px 0;
    border: none;

    &:before {
      // full-width divider
      content: '';
      display: block;
      position: absolute;
      right: 0;
      max-width: 100%;
      width: 100%;
      height: 1px;
      border-top: 1px solid lightgrey;
    }
  }
  label {
    font-size: $font-size-base - 4;
    margin-top: 1em;
    margin-bottom: 0.25em;
    color: #444;
  }
  input [type='text'],
  input [type='date'] {
    border-radius: 3px;
    border: solid 1px $slate-grey;
    background-color: #ffffff;
  }
  .divider {
    &:before {
      // full-width divider
      content: '';
      display: block;
      position: absolute;
      right: 0;
      max-width: 100%;
      width: 100%;
      height: 1px;
      border-top: 1px solid lightgrey;
    }
  }
  .buttons {
    padding: 2em 0;
    margin: 2em 0 0;
    &[class*=' col-'] {
    }
    &:before {
      // full-width divider
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: $negdivider;
      left: $negdivider;
      // max-width: 100%;
      // width: 100%;
      height: 1px;
      border-top: 1px solid lightgrey;
    }
    button {
      margin: 0 1em;
      width: auto;
    }
  }
  .error,
  .invalid-feedback,
  .alert {
    //display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.85em;
    color: $red;
  }
  .check-grid {
    display: inline-block;
    //min-width: 12%;
    margin-right: 1em;
  }

  &.nested {
    width: auto !important;
    max-width: auto !important;
    .prescription-options {
      padding: 0 1em;
    }
    h1 {
      &::after {
        // full-width divider
        content: '';
        display: block;
        position: absolute;
        right: $negdivider !important;
        left: $negdivider !important;
        height: 1px;
        margin: 1em 0;
        border-top: 1px solid lightgrey;
      }

      &:nth-child(1) {
        margin: 0 0 1em 0;
      }
      div > h1,
      &:nth-child(2) {
        margin: 2em 0 0 0;
      }
    }
    .buttons {
      &:before {
        // full-width divider
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: $negdivider - 16;
        left: $negdivider - 16;
        height: 1px;
        border-top: 1px solid lightgrey;
      }
    }
  }

  //SPECIFIC Field Fixes
  #patientState {
    padding-right: 1em;
  }
}

/* eslint-disable max-len, sort-keys */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  padding: 0 0.25em !important;
  color: lighten($slate-grey, 25%) !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  padding: 0 0.5em !important;
  color: lighten($slate-grey, 25%) !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  padding: 0 0.5em !important;
  color: lighten($slate-grey, 25%) !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  padding: 0 1em;
  color: lighten($slate-grey, 25%) !important;
}
::placeholder {
  padding: 0 1em;
  color: red;
}
.prescription-options {
  width: 100%;
  padding: 0 1em;
  h3 {
    margin-right: 2em;
  }
}
