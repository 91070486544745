.tooltip {
  .tooltip-inner {
    background-color: $brand-purple !important;
  }
  .arrow {
    &:before {
      border-top-color: $brand-purple !important;
    }
  }
}

.reset-help {
  .tooltip-inner {
    min-width: 500px !important;
  }
}
