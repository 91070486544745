.dropzone {
  margin: 0.25em 0 0 0;
  display: flex;
  align-items: stretch;
  flex-grow: 2;

  &__wrapper {
    border: 0;
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    border: dashed 1px project-color('gray');
    border-radius: 0.25rem;
    &:hover,
    &:active {
      cursor: pointer;
      background-color: lighten($brand-purple, 70%);
      border-color: $bright-purple;
      border-style: solid;
      box-shadow: 0 0 0 0.2rem rgba(73, 35, 101, 0.25);
      color: $bright-purple;
      transition: $transition;

      .mdi-icon,
      small {
        color: $brand-purple;
        transition: $transition;
      }
      strong {
        color: $brand-purple;
      }
    }
    $colorduration: 1.125s;
    &:active {
      animation: colorchange $colorduration infinite;
      -webkit-animation: colorchange $colorduration infinite;
      // -webkit-transition: background-color 1000ms linear;
      // -ms-transition: background-color 1000ms linear;
      // transition: background-color 1000ms linear;
    }
    @-moz-keyframes colorchange /* Firefox */ {
      0% {
        background: $soft-purple;
      }
      50% {
        background: white;
      }
      100% {
        background: $soft-purple;
      }
    }

    @-webkit-keyframes colorchange /* Chrome */ {
      0% {
        background: $soft-purple;
      }
      50% {
        background: white;
      }
      100% {
        background: $soft-purple;
      }
    }
  }

  &__input {
    padding: 0.825rem 1.25rem 1.25rem;
    margin: 0 auto;
    font-size: 0.9rem;
    line-height: 1.3;
    text-align: center;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      //background: lighten($brand-purple, 70%);
      border-color: #8d48c0;

      .mdi-icon {
        color: $brand-purple;
      }
    }
    .reject-notice {
      display: none;
    }

    .mdi-icon {
      position: relative;
      display: block;
      margin: 1em auto;
      top: 9px;
      width: 50px;
      height: 50px;
    }

    &--active {
      color: $brand-purple;
      border-color: #8d48c0;
      border-style: solid;
      background: lighten($brand-purple, 70%);
      .mdi-icon {
        color: inherit;
      }
    }

    &--reject {
      border-color: darken($status-1, 10%) !important;
      background: lighten($status-1, 20%);
      color: darken($status-1, 30%);
      .mdi-icon,
      .dropzone__fake_link,
      small,
      a {
        color: inherit;
      }
      // div {
      //   display: none;
      //   visibility: hidden;
      //   height: 0;
      // }
      // .reject-notice {
      //   display: block;
      // }
    }
  }

  &__fake_link {
    color: $link-color;
  }

  &__progress {
    margin-bottom: 1rem;

    .progress-bar {
      background: project-color('gray-darker');
    }

    p:last-of-type {
      margin-top: 5px;
    }
  }

  &__files {
    @include list-unstyled;

    &__file {
      @include file-block;
    }
  }
}
