.summary {
  padding: 1em;
  color: $slate-grey;
  .summary-header {
    position: relative;
    padding: 1em 0;
    h1 {
      @include rem-fallback(font-size, $font-size-h1);
      color: $slate-grey;
      display: inline-block;
      span {
        color: $brand-blue;
        @media screen and (max-width: $break-medium) {
          display: block;
        }
      }
    }
    font-size: $font-size-base - 0.15;
    line-height: 1em;
    margin: 0 0 2em;
    width: 100%;
    display: block;

    // full-width divider
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: $negdivider;
      right: $negdivider;
      height: 1px;
      margin: 1em 0;
      border-top: 1px solid lightgrey;
    }
    button {
      //position: absolute; //7.21 CHANGED FROM relative
      right: 0;
      float: right;
      margin: 0;
      margin-top: $negdivider / 2;

      @media screen and (max-width: $break-medium) {
        margin: 0;
        top: 15%;
      }
      .NewCaseBtn {
        path {
          fill: lighten($slate-grey, 20%);
        }
      }
      &.home {
        color: $slate-grey;
        background: white;
      }
      &:hover {
        border: 1px solid $bright-purple;
        background: white;
        // TODO: this shoud be used to color NewCaseBtn Component
        // might need refactored to the mdi-icon instead
        // color: $primary;
        box-shadow: 0 0 0 0.2rem rgba(73, 35, 101, 0.125);

        .NewCaseBtn {
          path {
            fill: $primary;
          }
        }
      }
    }
  }
  .summary-body {
    padding: 0 0 1em 0;
    h1 {
      font-size: $font-size-h2;
    }
    &.card-deck {
      align-items: flex-start;
    }
    .card {
      box-shadow: none;
      border: 1px solid lightgrey;
      border-radius: 3px;

      .card-header,
      .card-footer {
        background: white;
      }
      .card-body,
      .card-footer {
        //border-top: 1px solid lightgrey;
        padding: 0;
        .card-title {
          font-weight: 700;
          font-size: 1.5em;
          margin: 1em $pad-data 1rem $pad-data;
        }
        .card-subtitle {
          margin: 0 $pad-data 0.8em $pad-data;
        }
        .card-status {
          padding: 0;
          margin: 0 1em 1em;
          p {
            font-size: 1em;
          }
          &.status-1 {
            p {
              background-color: lighten($status-1, 10%);
              color: darken($status-1, 30%);
            }
          }
          &.status-2 {
            p {
              background-color: lighten($status-2, 10%);
              color: darken($status-2, 30%);
            }
          }
          &.status-3 {
            p {
              background-color: lighten($status-3, 10%);
              color: darken($status-3, 30%);
            }
          }
        }
        dl {
          display: block;
          margin-bottom: 0;
          font-size: 1em !important;
          &:nth-child(even) {
            background-color: #f7f7f7;
          }
          &:hover {
            color: #48555f;
            background-color: #e8e8e8;
          }
          dt,
          dd {
            line-height: 2.25em;
            display: inline-block;
            padding: 0 $pad-data;
            margin-bottom: 0;
            min-height: $card-dl-minheight;
          }
          dt {
            width: 50%;
          }
          dd {
            width: 50%;
          }
        }
        hr {
          margin: 1em 0;
        }
      }
      .card-footer {
        padding: 1.25em $pad-data;
        .card-status {
          @include rem-fallback(margin-top, 0.5);
        }
      }
    }
    $summary-minheight: 200px;
    .summary-content {
      .img-wrapper {
        min-height: $summary-minheight;
        height: $summary-minheight;
        display: flex;
        align-items: center;
        padding: 3em;
        @media screen and (max-width: $break-large) {
          min-height: $summary-minheight;
          height: $summary-minheight;
        }
        @media screen and (max-width: $break-medium) {
          min-height: 100px;
          padding: 2em;
          height: auto;
        }
      }
      .insurance-text {
        min-height: $summary-minheight;
        height: $summary-minheight;
        display: flex;
        align-items: center;
        padding: 3em;

        h1 {
          font-weight: 700;
          text-align: center;
          width: 100%;
          text-transform: capitalize;
        }
      }
      &.medication-card {
        .card-footer {
          padding: 0.75rem 1.25rem;
        }
      }
      &.patient-card,
      &.insurance-card {
        .card-body {
          border-top: none;
        }
        .media {
          h1,
          h2,
          h3,
          h4 {
            padding: 0;
          }
          .media-left {
            margin-right: 1em;
          }
        }
      }

      &.case-manager-card {
        text-align: center;
        .img-wrapper {
          min-height: 266px;
          height: 266px;
          display: flex;
          align-items: center;
          @media screen and (max-width: $break-large) {
            min-height: 266px;
            height: 266px;
          }
          @media screen and (max-width: $break-medium) {
            min-height: 100px;
            padding: 2em;
            height: auto;
          }

          .card-img-top {
            border-radius: 50%;
            max-height: 200px;
            max-width: 200px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            padding: 0;
          }
        }
        .card-title,
        .card-subtitle,
        .card-text {
          text-align: center;
        }
      }
    }
  }
  .summary-details {
    display: block;
    width: 100%;
    position: relative;
    margin: 2em 0;
    padding: 1em 0;

    // full-width divider
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -18px;
      left: -18px;
      height: 1px;
      border-top: 1px solid lightgrey;
    }
    dl {
      display: block;
      width: 50%;
      float: left;
      margin-bottom: 0;
      font-size: 0.9em;
      dt,
      dd {
        line-height: $card-dl-minheight;
        display: inline-block;
        padding-right: 1em;
        margin-bottom: 0;
        min-height: $card-dl-minheight;
      }
      dt {
        width: 40%;
      }
      dd {
        width: 60%;
      }
    }
  }
}
.buttons {
  padding: 2em 0;
  margin: 2em 0 0;

  @include media-breakpoint-down(xs) {
    margin: 0;
    padding: 1em 0;
    button {
      margin: 0.5em 0 !important;
      display: block !important;
      width: 100%;
      margin: 0.25em 0;
      float: left;
    }
  }

  // full-width divider
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -18px;
    left: -18px;
    height: 1px;
    border-top: 1px solid lightgrey;
  }
  button {
    margin: 0 1em;
  }
}
