.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  //height: $cf-footer-height - 15;
  padding: 5px;
  background-color: white;
  color: $slate-grey;
  text-align: center;
  vertical-align: middle;
  z-index: 2;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid $primary;
  .container {
    position: relative;
    height: 100%;
    .cf-footer__logo {
      //display: inline-block;
      height: 20px;
      margin: 0;
      float: left;
      @include media-breakpoint-down(sm) {
        margin: 0.5em auto;
        float: none;
        display: block;
      }
    }
    p {
      font-size: 11px;
      padding: 0;
      margin: auto;
      display: block;
      vertical-align: middle;

      .ver {
        float: right;
        text-align: right;
        min-width: 162px;
        min-height: 10px;
        @include media-breakpoint-down(md) {
          float: none;
          text-align: center;
        }
      }
    }
  }
}
body {
  padding-bottom: $cf-footer-height !important;
}
