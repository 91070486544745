html,
body {
  overflow-x: hidden;
}
.full-width-bar {
  margin: 0 -9999rem;
  /* add back negative margin value */
  padding: 0.25rem 9999rem;
  background: rgba(0, 0, 0, 0.5);
}
.single-article hr {
  margin: 30px -20px 20px;
  border: 0;
  border-top: 1px solid #c9c7c7;
}
.raw {
  text-align: center;
  width: 100%;
  padding: 4px;
  background: #adadad;
  color: #6b6b6b;
  font-family: monospace;
  font-size: 0.9em;
  display: block;
  margin: -3% 0;
}
.text {
  &-white * {
    color: white !important;
  }
  &-primary * {
    color: $primary !important;
  }
}
.vis-br {
  display: block;
  line-height: 0;
  height: 1px;
}
.rwd-br {
  display: none;
  @include media-breakpoint-down(xs) {
    display: block;
  }
  @include media-breakpoint-down(md) {
    display: block;
  }
}
.inline {
  display: inline !important;
}
.inline-b {
  display: inline-block !important;
  width: auto !important;
}
