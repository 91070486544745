.register {
  position: relative;
  @include clearfix();
  padding: 2em 1em;
  margin: 2em auto;

  // TODO: add specific calls to size and errors
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // padding: 1em;
  // margin: 2em 0;
  background-color: white;
  border-radius: 2px;
  //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  //padding: 0 1em;
  .logo {
    display: block;
    width: 100%;
    text-align: center;
    max-height: 100px;
    min-height: 75px;
    height: 100px;
    margin: 2em 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0 15px;
  }
  h2 {
    text-align: center;
    color: $slate-grey;
  }
  label {
    color: $slate-grey;
  }
  select {
    &.form-control {
      padding: $input-padding-y $input-padding-x - 0.25;
    }
    option {
      &[disabled] {
        color: blue !important;
      }
    }
  }
  .form-control {
    padding: $input-padding-y $input-padding-x;
  }
}
