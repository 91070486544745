.navbar {
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

  .navbar-brand {
    outline: none;
    @media screen and (max-width: $break-medium) {
      margin-right: 0;
    }
    @media screen and (max-width: $break-small) {
      margin-right: 0;
    }
    .logo {
      width: $sm-menu-brand-width + 20;
      height: $sm-menu-brand-width / 2;
      display: block;
      @media screen and (max-width: $break-small) {
        width: $sm-menu-brand-width;
      }
    }
  }
  .navbar-nav {
    .enroll {
      padding-right: 1em;
      margin-right: 1em;
      //border-right: 1px solid lightgrey;
      vertical-align: middle;
      display: block;
      margin: auto;
      height: $btn-height;

      @media screen and (max-width: $break-small) {
        width: $sm-menu-btn !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-top: $sm-menu-btn-vspace !important;
        margin-bottom: $sm-menu-btn-vspace !important;
      }
    }
    .nav-link {
      // margin-left: 1em;
      transition: $transition;
      outline: none;
      &:hover {
        transition: $transition;
        color: $brand-purple;
      }

      @media screen and (max-width: $break-small) {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        width: $sm-menu-btn !important;
      }
      // TODO: add md style or refine to capture both
      // @include media-breakpoint-up(md) { * {background: red !important;} }
    }
    @media screen and (max-width: $break-small) {
      padding-bottom: $sm-menu-btn-vspace !important;
    }
  }
  .input-group-append {
    button,
    .btn {
      line-height: 1em !important;
      .mdi-icon {
        font-size: 2em;
      }
      &-outline-primary {
        background-color: #ffffff;
        color: #828282;
        border-color: #ced4da;
        border-left: none;
        border-left-color: #ffffff;

        &:hover {
          background-color: $brand-purple;
          color: #ffffff;
          border-top-color: $brand-purple;
          border-bottom-color: $brand-purple;
          border-right-color: $brand-purple;
        }
      }
    }
  }
  .dropdown-menu{
    top: 100% !important;
    &-right {
      right: -1em;
      box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);

      .dropdown-item {
        color: $slate-grey;
        transition: $transition;
        &:hover,
        &:focus {
          color: $brand-purple;
          background-color: $soft-purple;
          transition: $transition;
          cursor: pointer;
        }
      }
      .btn-primary{
        color: white;
        background: $brand-purple;
        font-size: 1em;
        width: 95%;
        margin: 0 auto;
        &:hover{
          color: white !important;
        }
      }
    }
  }
  &-toggler{
    border-color: transparent !important;
    outline: none !important;

    &:hover{
      color: $brand-purple !important;
    }
  }
}


.nav-link__icon {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.25rem;
}

.navbar-light .navbar-nav {
  .btn.btn-primary.nav-link, .btn.btn-info.nav-link {
    color: white;
    padding: 0.5em 1.5em;
    white-space: nowrap;
  }
}
@media (max-width: $screen-xs-max) {
  .navbar-light .navbar-nav {
    .btn.btn-primary.nav-link {
      margin-top: 2em;
    }
  }
}
