$arrowSize: 36px;

.prescription-options {
  width: 100%;
  .accordion {
    .card {
      cursor: pointer;
      border-radius: 3px;
      border: 1px solid lightgrey;
      margin-bottom: 1em !important;

      &[aria-expanded='true'] {
        border: 1px solid $bright-purple;
        box-shadow: 0 0 0 0.2rem rgba(73, 35, 101, 0.25);

        .card-header {
          color: #5a6771;

          .CircleArrow {
            -webkit-moz-transform: rotate(90deg);
            -moz-moz-transform: rotate(90deg);
            -ms-moz-transform: rotate(90deg);
            -o-moz-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }

      &[aria-expanded='false'] {
        border: 1px solid lightgrey;
        box-shadow: none;

        .card-header {
          color: #758795;
        }
      }

      &:hover {
        box-shadow: 0 5px 5px 0 rgba(73, 35, 101, 0.15);
      }

      .card-header {
        padding: 1.5em 1.5em;
        h2 {
          font-size: 24px;
          margin-bottom: 0;
          padding: 0;
        }

        .CircleArrow {
          position: absolute;
          width: $arrowSize;
          height: $arrowSize;
          right: $arrowSize - 12;
          top: $arrowSize - 3;
        }
      }

      .card-body {
        .invalid-feedback,
        small {
          font-size: 0.8em;
        }
        article {
          padding: 2em;
        }
      }

      .collapse,
      .collapsing {
        font-size: 1em;
        &.show {
        }
      }

      &:not(:first-of-type):not(:last-of-type) {
        border-radius: 3px;
        // border: 1px solid lightgrey;
      }
    }
  }
}
