.modal__slideout {
  margin: 0;

  .modal.fade & {
    transform: translate(-300px, 0);
  }

  .modal.fade.show & {
    transform: translate(0, 0);
  }

  .modal-content {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: initial;
    width: 700px;
    overflow: scroll;
    background-color: project-color('white');
    border: none;
    border-radius: 0;
  }
}
