.btn {
  font-size: $font-size-base - 0.1;
  &.btn-primary {

    &.disabled {
      background-color: $grey-medium;
      border-color: darken ($grey-medium 30%);
      cursor: not-allowed !important;
      color: white;

      &:active,
      &:focus {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }
    }
    &:disabled {
      &:active,
      &:focus {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }
    }
    &:hover {
      background-color: darken($bright-purple, 20%) !important;
      border-color: darken($bright-purple, 10%) !important;
      transition: $transition;
    }
    &:active {
      background-color: darken($bright-purple, 30%) !important;
      border-color: darken($bright-purple, 10%) !important;
      transition: $transition;
    }
  }
  &.btn-info {
    
    &.disabled {
      background-color: $grey-medium;
      border-color: darken ($grey-medium 30%);
      cursor: not-allowed !important;
      color: white;

      &:active,
      &:focus {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }
    }
    &:disabled {
      &:active,
      &:focus {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }
    }
    &:hover {
      background-color: darken($brand-blue, 20%) !important;
      border-color: darken($brand-blue, 10%) !important;
      transition: $transition;
    }
    &:active {
      background-color: darken($brand-blue, 30%) !important;
      border-color: darken($brand-blue, 10%) !important;
      transition: $transition;
    }
  }
  &-link{//overrides bootstrap btn-link
    &:hover{
      text-decoration-color: darken($bright-purple, 10%) !important;//typically inheritted by text color
      -webkit-text-decoration-color: darken($bright-purple, 10%) !important;//chrome <50
      -moz-text-decoration-color: darken($bright-purple, 10%) !important;//moz <36
    }
  }
  &:hover{
    cursor: pointer;
  }
}

.btn-min-width {
  min-width: 11.25rem;
}

.btn--no-shadow {
  box-shadow: none;
}

.btn-icon {
  position: relative;
  top: 3px;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  color: project-color('gray-darker');
}
.btn-disabled {
  opacity: 0.65;
  background-color: lighten($grey-medium, 20%);
  border-color: $grey-medium;
  cursor: not-allowed !important;
  color: darken($grey-medium, 30%);

  &:hover {
    color: darken($grey-medium, 30%);
  }
  &:active,
  &:focus {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
#complete{border-color: darken ($grey-medium 30%) !important;}
