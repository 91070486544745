@mixin file-block {
  @include Attachment-File-Text;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.5rem;
  line-height: 1;
  border: 1px solid project-color('gray-light');
  border-radius: 0.1875rem;

  .mdi-icon {
    position: relative;
    top: 1px;
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.125rem;
    color: project-color('gray-dark');
  }

  &__close {
    transform: scale(0.8);
  }
}

.file-block {
  @include file-block;

  &--in-main-content {
    background-color: project-color('gray-lighter');
  }
}
