.row.no-pad {
  margin-right: 0;
  margin-left: 0;
}
.row.no-pad > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.row.sm-pad {
  margin-right: 0;
  margin-left: 0;
}
.row.sm-pad > [class*='col-'] {
  padding-right: 6px;
  padding-left: 6px;
}
.mt-6 {
  margin-top: ($spacer * 6) !important;
}
.mb-6 {
  margin-bottom: ($spacer * 6) !important;
}
