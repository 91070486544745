.card-deck {
  align-items: flex-start;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
}
.card {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  transition: $transition;
  border-left: 0;
  border-right: 0;
  border-radius: 0 0 0.125rem 0.125rem;

  @include media-breakpoint-down(md) {
    margin-bottom: 2em !important;
  }
  &:hover {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    transition: $transition;
  }
  &:active {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.05);
    transition: $transition;
  }
  .card-header {
    background-color: white;
  }
  &.status-1 {
    border-top: $card-topborder-weight solid $status-1;
    .card-header {
      background-color: lighten($status-1, 20%);
      border-bottom: 1px solid lighten($status-1, 10%);
      color: darken($status-1, 30%);
    }
    .card-status {
      background-color: lighten($status-1, 25%);
      border-bottom: 1px solid lighten($status-1, 20%);
      p {
        background-color: lighten($status-1, 15%);
        color: darken($status-1, 30%);
      }
    }
    .therapy-status {
      background-color: #fff;
      color: darken($status-1, 30%);
    }
  }
  &.status-2 {
    border-top: $card-topborder-weight solid $status-2;
    .card-header {
      background-color: lighten($status-2, 20%);
      border-bottom: 1px solid lighten($status-2, 10%);
      color: darken($status-2, 30%);
    }
    .card-status {
      background-color: lighten($status-2, 25%);
      border-bottom: 1px solid lighten($status-2, 20%);
      p {
        background-color: lighten($status-2, 15%);
        color: darken($status-2, 30%);
      }
    }
    .therapy-status {
      background-color: #fff;
      color: darken($status-2, 30%);
    }
  }
  &.status-3 {
    border-top: $card-topborder-weight solid $status-3;
    .card-header {
      background-color: lighten($status-3, 20%);
      border-bottom: 1px solid lighten($status-3, 10%);
      color: darken($status-3, 30%);
    }
    .card-status {
      background-color: lighten($status-3, 25%);
      border-bottom: 1px solid lighten($status-3, 20%);
      p {
        background-color: lighten($status-3, 15%);
        color: darken($status-3, 30%);
      }
    }
    .therapy-status {
      background-color: #fff;
      color: darken($status-3, 30%);
    }
  }
  .card-header {
    padding: 0.75em;
  }
  .card-body {
    padding: 0;
  }
  .card-header {
    .card-title {
      //text-transform: capitalize;
      font-size: 1.125em;
      margin-bottom: 0.25rem;
      max-width: 70%;
    }
    .therapy-status {
      font-size: 0.65em;
      font-weight: 500;
      line-height: 1.5em;
      text-align: center;
      padding: 0.25em 0em;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: absolute;
      border-radius: 7px;
      top: 0.55em;
      right: 0.85em;
      box-shadow: 0 1px 2px 0px grey;
      color: grey;
      height: 35px;
      width: 100px;
      
      span {
        width: 100%;
      }
    }
    .two-tone {
      background: linear-gradient(180deg, white, 55%, white, 55%, #F6F6F6);
    }
    .copay {
      float: right;
      dt,
      dd {
        padding-right: 0;
        padding-left: 1em;
      }
    }
    dl {
      &.float-right {
        text-align: right;
        margin-right: 5px;
        * {
          padding: 0;
          margin-top: 0.65em;
        }
      }
      display: inline-block;
      margin-bottom: 0;
      font-size: 0.7em;
      dt,
      dd {
        display: inline-block;
        width: auto;
        padding-right: 1em;
        margin-bottom: 0;
      }
    }
  }
  .img-wrapper {
    min-height: 266px;
    height: auto;//266px
    display: flex;
    align-items: center;
    padding: 3em;
    @media screen and (max-width: $break-large) {
      min-height: 266px;
      height: auto; //266px;
    }
    @media screen and (max-width: $break-medium) {
      min-height: 100px;
      padding: 2em;
      height: auto;
    }
  }
  .card-status {
    padding: 0.5em;
    p {
      font-size: 0.7em;
      padding: 0.5em 1em;
      vertical-align: middle;
      margin-bottom: 0;
      border-radius: $card-border-radius;
    }
  }
  .card-body {
    color: $slate-grey;
    position: relative;

    // scrollbar width
    &::-webkit-scrollbar {
      width: 6px;
    }
    //on card-body:hover width expand
    &:hover {
      &::-webkit-scrollbar {
        width: 12px;
        transition: $transition;
        @include transition(width 0.3s ease);
      }
      &::-webkit-scrollbar-button:single-button {
        &:vertical:decrement {
          border-color: #f1f1f1 #f1f1f1 #999 #f1f1f1;
          border-width: 0 8px 8px 8px;
        }
      }
    }

    // Track
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #999;
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      // Handle on hover
      &:hover {
        background: $brand-purple;
        width: 12px;
      }
    }
    &::-webkit-scrollbar-button:single-button {
      background-color: #f1f1f1;
      display: block;
      border-style: solid;
      height: 8px;
      width: 16px;

      // Up
      &:vertical:decrement {
        border-width: 4px 10px 10px 10px;
        border-color: #f1f1f1 #f1f1f1 #999 #f1f1f1;
        &:hover {
          border-color: #f1f1f1 #f1f1f1 #555555 #f1f1f1;
        }
      }
      // Down
      &:vertical:increment {
        border-width: 8px 8px 0 8px;
        border-color: #999 #f1f1f1 #f1f1f1 #f1f1f1;
        &:hover {
          border-color: #555555 #f1f1f1 #f1f1f1 #f1f1f1;
        }
      }
    }

    &.card-note {
      max-height: 120px;
      min-height: 0;
      overflow-y: auto;
    }
    .card-text {
      font-size: 0.8em;
      padding: 12px;
    }
    dl {
      display: block;
      margin-bottom: 0;
      font-size: 1em;
      &.error {
        dt,
        dd {
          color: $red !important;
        }
      }
      dt,
      dd {
        line-height: 2em;
        display: inline-block;
        padding-right: 1em;
        margin-bottom: 0;
      }
      dt {
        width: 40%;
        float: left;
        clear: left;
      }
      dd {
        width: 60%;
      }
    }
  }
  .card-footer {
    font-size: 0.7em;
    background-color: white;
    padding: 1.15rem .75rem .75rem .75rem !important;
    
    .card-status {
      @include rem-fallback(margin-top, 0.5);
    }
    .dod-icon{
      padding-right: 2px;
    }
    .card-dod-refill  {
      float: left;
        .dod-refill-label{
          margin-bottom: 0;
          display: block;
          font-weight: 600;
          white-space: initial;
          font-size: .75rem;
        }
        .dod-refill-value {
          font-weight: 600;
          font-size: .69rem;
      }
      .supply-valid{
        color: $status-3; 
      }
      .supply-alert{
        color: $status-1;
      }
      .supply-warning{
        color:$status-2
      }
    }
  }
  &.medication-card {
    border-radius: $card-border-radius;
    border: $card-border-default;
    letter-spacing: 0.0025em;
    cursor: pointer;
    .card-body {
      border-top: none;//7.10 $card-border-default
      padding: 1.75em;

      .custom-control {
        label {
          cursor: pointer;
          color: $slate-grey;
          &:hover {
            color: darken( $slate-grey, 25% );
            transition: $transition;
          }
        }
      }
    }
  }
  &.coverage-card,
  &.insurance-card {
    max-width: 33%;
    justify-content: center;

    .card-body {
      border-top: none;
      padding: 0;
      h6 {
        color: $brand-blue;
        padding: 1em $pad-data 0.5em !important;
      }
      dl {
        width: 100%;
        position: relative;
        min-height: $card-dl-minheight;
        float: left;
        dt,
        dd {
          padding: 0 $pad-data;
          min-height: $card-dl-minheight;
        }
        dt {
          width: $card-dl-dt;
        }
        dd {
          width: $card-dl-dd;
        }
        @include media-breakpoint-down(lg) {
          dt {
            width: $card-dl-dt;
          }
          dd {
            width: $card-dl-dd;
          }
        }

        &.warning {
          background-color: lighten($status-2, 20%) !important;
          color: darken($status-2, 30%) !important;

          &::after {
            content: ' diff ';
            vertical-align: -50%;
            padding: 0.125em 0.25em;
            line-height: 1em;
            display: block;
            position: absolute;
            top: 0.25em;
            right: 0.25em;
            width: auto;
            height: auto;
            border: 1px solid darken($status-2, 30%);
          }
        }
        &:nth-child(even) {
          background-color: #f7f7f7;
        }
        &:hover {
          color: #48555f;
          background-color: #e8e8e8;
        }
      }
    }
    .more-info {
      padding: 1em 0 2em 0;
      h6 {
        color: $brand-blue;
        padding: 1em $pad-data inherit;
      }

      dl {
        display: block;
        margin-bottom: 0;
        font-size: 0.9em;
        min-height: $card-dl-minheight;
        height: auto;
        dt,
        dd {
          line-height: $card-dl-minheight;
          display: inline-block;
          padding-right: $pad-data;
          margin-bottom: 0;
          min-height: $card-dl-minheight;
        }
        dt {
          padding: 0 $pad-data;
          width: $card-dl-dt;
          float: left;
        }
        dd {
          width: $card-dl-dd;
          padding: 0 $pad-data;
        }
        &:nth-child(even) {
          background-color: #f7f7f7;
        }
        &:hover {
          color: #48555f;
          background-color: #e8e8e8;
        }
      }
    }
    .btn-link {
      padding: 1.25em 0;
      border-radius: 0;

      // full-width divider
      &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        max-width: 100%;
        width: 100%;
        height: 1px;
        margin: -1.3em 0;
        border-top: 1px solid lightgrey;
      }
    }
  }
  &.coverage-card,
  &.insurance-card,
  &.medication-card,
  &.patient-card {
    @include media-breakpoint-down(lg) {
      min-width: 250px;
      max-width: 100%;
      width: 100%;
      margin-bottom: 2em;

      .img-wrapper {
        height: auto; //max-height: 266px;
        width: auto;

        .card-img-top {
          width: 100%;
          height: auto;
          margin: auto;
        }
      }
    }
    .card-body {
      font-size: 0.7em;
    }
    .card-footer,
    .collapse,
    .collapsing {
      border-top: $card-border-default;
      .card-status {
        padding: 0;
        margin: 0;
        p {
          font-size: $font-size-base - 0.25;
          margin: 1em;
        }
        &.status-1 {
          p {
            background-color: lighten($status-1, 10%);
            color: darken($status-1, 30%);
            border: 1px solid lighten($status-1, 10%);
          }
        }
        &.status-2 {
          p {
            background-color: lighten($status-2, 20%);
            color: darken($status-2, 30%);
            border: 1px solid lighten($status-2, 10%);
          }
        }
        &.status-3 {
          p {
            background-color: lighten($status-3, 10%);
            color: darken($status-3, 30%);
            border: 1px solid lighten($status-3, 10%);
          }
        }
      }
      dl {
        display: block;
        margin-bottom: 0;
        font-size: 0.9em;
        dt,
        dd {
          line-height: $card-dl-minheight;
          display: inline-block;
          padding-right: 1em;
          margin-bottom: 0;
          min-height: $card-dl-minheight;
        }
        dt {
          width: $card-dl-dt;
          padding: 0 $pad-data;
        }
        dd {
          width: $card-dl-dd;
          padding: 0 $pad-data;
        }
        &:nth-child(even) {
          background-color: #f7f7f7;
        }
        &:hover {
          color: #48555f;
          background-color: #e8e8e8;
        }
        &:nth-last-child(1) {
          border-bottom: 1px solid rgba(51, 51, 51, 0.1);
        }
      }
    }
  }
  &.coverage-card{
    .btn-link{
      padding: 0;
      &:before{
        display: none;
      }
    }
  }
  &.location-card {
    border-radius: $card-border-radius;
    border: $card-border-default;
    margin-top: 1.5em;
    padding: 1em;
    p {
      padding: 0;
    }
  }
  .media {
    display: flex;
    align-items: flex-start;
    margin: 1em 0 0 0;
    padding: $card-body-pad;
    border-top: $card-border-default;

    .media-left {
      .initial-bubble {
        background: $primary;
        display: flex;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        text-align: center;
        flex-flow: column nowrap;
        justify-content: center;
        overflow: hidden;
        &--text {
          color: white;
          font-weight: bold;
          font-size: 1.7em;
        }
        .case-manager-pic {
          max-width: 80px;
          max-height: 80px;
        }
      }
    }
    .media-body {
      flex: 1 1;
    }
  }
  .collapse,
  .collapsing {
    transition: $transition;
    font-size: 0.7em;
    color: $slate-grey;
    border-top: $card-border-default;
    h6 {
      padding: 1em $pad-data 0 !important;
    }
  }
  &.patient-dif {
    max-width: 20rem;
  }
  hr {
    margin-top: 0;
    margin-bottom: 0;
  }
  .note {
    position: relative;
    background: $pale-yellow;
    padding: 1.5em;
    font-size: 1em;
    margin: 2em 0;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-width: 0 20px 20px 0;
      border-style: solid;
      border-color: #eae384 #fff;
    }
  }
}
.card-footer {
  font-size: 0.7em;
  background-color: white;
  padding: .75rem .75rem !important;
}
