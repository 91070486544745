/*
  Variables
  -
  All project variables go here.
  Additionally, we will be overwriting Bootstrap variables
  in this file.
-------------------------------------------------- */

/*
  Project Variables
-------------------------------------------------- */

// Colors
$body-bgcolor: #f0f4f7;
$apricot: #ffc06f;
$aqua-marine: #50e3c2;
$black: #333;
$blueberry: #4b4893;
$brand-purple: #492365;
$bright-purple: #8d48c0;
$soft-purple: #eddcfa;
$brand-blue: #296293;
$cream-background: #f9f9f9;
$pharm-blue: #f1fdfe;
$dodger-blue: #4f94fb;
$nimbus-blue: #73acff;
$gray-light: #ddd;
$gray-lighter: #f2f2f2;
$grey-medium: #b3b3b3;
$gray: #9d9d9d;
$grey: #9d9d9d;
$gray-dark: #70757a;
$gray-darker: #5a6771;
$slate-grey: #5a6771;
$pale-salmon: #ffad9b;
$pale-yellow: #fff9aa;
$white: #fff;
$red: #c70000;

$project-colors: () !default;
$project-colors: map-merge(
  (
    'blueberry': $blueberry,
    'gray-darker': $gray-darker,
    'slate-grey': $slate-grey,
    'gray': $gray,
    'grey': $grey,
    'gray-light': $gray-light,
    'black': $black,
    'white': $white,
    'pale-salmon': $pale-salmon,
    'aqua-marine': $aqua-marine,
    'apricot': $apricot,
    'pale-yellow': $pale-yellow,
    'gray-lighter': $gray-lighter,
    'gray-dark': $gray-dark,
    'dodger-blue': $dodger-blue,
    'nimbus-blue': $nimbus-blue,
    'brand-purple': $brand-purple,
    'brand-blue': $brand-blue,
    'cream-background': $cream-background,
    'red': $red
  ),
  $project-colors
);
@function project-color($key: 'blueberry') {
  @return map-get($project-colors, $key);
}

// Dimensions and Spacing
$cf-header-height: 70px;
$cf-footer-height: 50px;
$floating-header--margin-top: 8.125rem;
$floating-header--margin-bottom: 4.0625rem;
$transition: all 0.15s;
$negdivider: -18px;

/*
  Bootstrap Variables
-------------------------------------------------- */

/* Typographic baseline -------------------------- */
/* stylelint-disable */
$font-family-base: 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
/* stylelint-enable */
$font-size-base: 1rem; //0.8125rem; EPA value
$body-color: project-color('slate-grey');
$lead-font-size: 1.3rem;
$letter-spacing: 0.0025em;

/* Font Variables ------------------------------- */
$font-size-h1: $font-size-base + 0.25;
$font-size-h2: $font-size-base + 0.625;
$font-size-h3: $font-size-base + 0.42188rem;
$font-size-h4: $font-size-base + 0.21875rem;
$font-size-h5: $font-size-base + 0.01563rem;
$font-size-h6: 0.8125rem;

/* Bootstrap color changes --------------------- */
$primary: project-color('brand-purple');
$info: project-color('brand-blue');

/* Nav ----------------------------------------- */
$sm-menu-brand-width: 100px; // responsive height auto
$sm-menu-btn: 90%;
$sm-menu-btn-vspace: 1em;
$sm-menu-btn-hspace: 1em;

/* Cards --------------------------------------- */
$card-topborder-weight: 3px;
$card-border-radius: 3px;
$card-border-default: 1px solid lightgrey;
$pad: 8px;
$pad-data: $pad + 4;
$card-body-pad: 1.75em;
$card-dl-font: 0.9em;
$card-dl-dt: 50%;
$card-dl-dd: 50%;
$card-dl-minheight: 2.25em;

/* Buttons ------------------------------------- */
$btn-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
$btn-border-radius: 0.1875rem;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$btn-height: 40px;

/* Progress ------------------------------------ */
$progress-height: 4px;
$progress-border-radius: 0;

/* Statuses ------------------------------------ */
$status-1: #ec7262;
$status-2: #f9be69;
$status-3: #50e3c2;

/* Media Breaks -------------------------------- */
$break-small: 767px; // 576px
$break-medium: 768px;
$break-large: 992px;
$break-xlarge: 1200px;
