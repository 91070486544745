@mixin sscrollbar() {
  // scrollbar width
  &::-webkit-scrollbar {
    width: 6px;
  }
  //on card-body:hover width expand
  &:hover {
    &::-webkit-scrollbar {
      width: 12px;
      transition: $transition;
      @include transition(width 0.3s ease);
    }
    &::-webkit-scrollbar-button:single-button {
      &:vertical:decrement {
        border-color: #f1f1f1 #f1f1f1 $grey #f1f1f1;
        border-width: 0 8px 8px 8px;
      }
    }
  }

  // Track
  &::-webkit-scrollbar-track {
    background: lighten($grey, 33%);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $grey;
    border-top: 2px solid #f1f1f1;
    border-bottom: 2px solid #f1f1f1;
    // Handle on hover
    &:hover {
      background: $slate-grey;
      width: 12px;
    }
  }
  &::-webkit-scrollbar-button:single-button {
    background-color: #f1f1f1;
    display: block;
    border-style: solid;
    height: 8px;
    width: 16px;

    // Up
    &:vertical:decrement {
      border-width: 4px 10px 10px 10px;
      border-color: #f1f1f1 #f1f1f1 $grey #f1f1f1;
      &:hover {
        border-color: #f1f1f1 #f1f1f1 $bright-purple #f1f1f1;
      }
    }
    // Down
    &:vertical:increment {
      border-width: 8px 8px 0 8px;
      border-color: $grey #f1f1f1 #f1f1f1 #f1f1f1;
      &:hover {
        border-color: $bright-purple #f1f1f1 #f1f1f1 #f1f1f1;
      }
    }
  }
}

@mixin border($direction, $width, $style, $color) {
  border-#{$direction}: $width $style $color;
}
@mixin divider() {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  max-width: 100%;
  width: 100%;
  height: 1px;
  margin: 1em 0;
  border-top: 1px solid lightgrey;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

/*
  REM Fallback

  usage  = @include rem-fallback(margin, 10, 20, 30, 40);
  output = margin: 160px 320px 480px 640px;
           margin: 10rem 20rem 30rem 40rem;
-------------------------------------------------- */
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@mixin rem-fallback($property, $values...) {
  $max: length($values);
  $pxValues: '';
  $remValues: '';

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value * 16}px;

    @if $i < $max {
      $pxValues: #{$pxValues + ' '};
    }
  }

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValues: #{$remValues + $value}rem;

    @if $i < $max {
      $remValues: #{$remValues + ' '};
    }
  }

  #{$property}: $pxValues;
  #{$property}: $remValues;
}
