.uploaded-file {
  padding: 1rem 0 1.5rem;
  border: 1px solid project-color('gray-light');
  border-radius: 0.3125rem;

  .mdi-icon {
    width: 3.75rem;
    height: 3.75rem;
    color: project-color('gray-light');
  }

  p {
    margin-bottom: 0;
  }
}
