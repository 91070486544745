.file-library {
  &.empty {
    margin-top: 0.5em;
    .dropzone {
      padding: 0;
    }
  }
  .dropzone {
    order: 2;
    flex-grow: 2;
    //flex: 0 0 25%;
    @media screen and (max-width: $break-large) {
      flex: 0 0 100%;
      margin-top: 1em;
    }
  }
  .file-list-col {
    order: 1;
    flex-grow: 2;
    flex: 75% 0 0;
  }
  .file-list {
    order: 1;
    flex-grow: 2;
    flex: 75% 0 0;

    @include media-breakpoint-down(xs) {
    }
  }
  &.empty {
    .dropzone {
      order: 1;
      flex-grow: 2;
      flex: 100% 0 0;
    }
    .file-list-col {
      order: 2;
      flex-grow: 2;
      flex: 100% 0 0;
    }
    .file-list {
      order: 2;
      flex-grow: 2;
      flex: 100% 0 0;
    }
  }
}
.file-list {
  display: flex;

  // TODO: this will be for when we have wording for NO FILES and min-height on UPLOADED
  section {
    &.uploaded {
    }
    &.generated {
    }
  }
  &__file {
    background: white;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    transition: $transition;
    padding: 1em;
    min-height: 60px;
    margin: 0.25em 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    &__info {
      flex-flow: column;

      &__name {
        font-size: 1em;
      }
      &__details {
        font-size: 0.8em;
      }
    }
    &__actions {
      flex-flow: column;

      button {
        color: grey;
        margin: 0 0.25em;
        &:hover {
          color: $brand-purple !important;
          border: 1px solid #8d48c0;
          box-shadow: 0 0 0 0.2rem rgba(73, 35, 101, 0.125);
        }
      }
    }
    &.default {
      border-left: 3px solid $bright-purple;
    }
    &.status-1 {
      border-left: 3px solid $status-1;
    }
    &.status-2 {
      border-left: 3px solid $status-2;
    }
    &.status-3 {
      border-left: 3px solid $status-3;
    }
  }
}
