.rbt {
  outline: none;

  .rbt-menu {
    margin-bottom: 2px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    &.dropdown-menu {
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
      &.show {
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05) !important;
      }
    }
  }

  .rbt-menu > li a {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rbt-menu > li a:focus {
    outline: none;
  }

  .rbt-menu-pagination-option {
    text-align: center;
  }

  .rbt .rbt-input-main::-ms-clear {
    display: none;
  }

  .rbt-input-multi {
    cursor: text;
    overflow: hidden;
    position: relative;
    height: auto;
  }

  .rbt-input-multi.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(73, 35, 101, 0.25);
    -moz-box-shadow: 0 0 0 0.2rem rgba(73, 35, 101, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(73, 35, 101, 0.25);
    border-color: $bright-purple;
    outline: 0;
  }

  .rbt-input-multi.form-control[disabled] {
    background-color: #e9ecef;
    opacity: 1;
  }

  .rbt-input-multi input::-moz-placeholder {
    color: #999;
    opacity: 1;
  }

  .rbt-input-multi input:-ms-input-placeholder {
    color: #999;
  }

  .rbt-input-multi input::-webkit-input-placeholder {
    color: #999;
  }

  .rbt-input-multi .rbt-input-wrapper {
    margin-bottom: -4px;
    margin-top: -1px;
    overflow: hidden;
  }

  .rbt-input-multi .rbt-input-main {
    height: 20px;
    margin: 1px 0 4px;
  }

  .rbt-input-multi .rbt-input-hint-container {
    display: inline-block;
  }

  .rbt-input-multi.input-lg .rbt-input-main,
  .rbt-input-multi.form-control-lg .rbt-input-main {
    height: 24px;
  }

  .rbt-input-multi.input-sm .rbt-input-main,
  .rbt-input-multi.form-control-sm .rbt-input-main {
    height: 18px;
  }

  .rbt-close {
    z-index: 1;
  }

  .rbt-close-lg {
    font-size: 24px;
  }

  .rbt-token {
    background-color: $soft-purple;
    border: 0;
    border-radius: 2px;
    color: $bright-purple;
    display: inline-block;
    line-height: 1em;
    margin: 0 3px 3px 0;
    padding: $pad - 2 $pad * 1.5;
    position: relative;
    border: 1px solid $bright-purple;
  }

  .rbt-token-disabled {
    background-color: #ddd;
    color: #888;
    pointer-events: none;
  }

  .rbt-token-removeable {
    cursor: pointer;
    padding-right: 21px;
  }

  .rbt-token-active {
    background-color: $soft-purple;
    color: $brand-purple;
    outline: none;
    text-decoration: none;
    border: 1px solid $brand-purple;
  }

  .rbt-token .rbt-token-remove-button {
    bottom: 0;
    color: inherit;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 3px 7px;
    position: absolute;
    right: 0;
    text-shadow: none;
    top: -2px;
  }

  .rbt-loader {
    -webkit-animation: loader-animation 600ms infinite linear;
    -o-animation: loader-animation 600ms infinite linear;
    animation: loader-animation 600ms infinite linear;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    border-top-color: $bright-purple;
    display: block;
    height: 16px;
    width: 16px;
  }

  .rbt-loader-lg {
    height: 20px;
    width: 20px;
  }

  .rbt-aux {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    bottom: 0;
    justify-content: center;
    pointer-events: none;
    /* Don't block clicks on the input */
    position: absolute;
    right: 0;
    top: 0;
    width: 34px;
  }

  .rbt-aux-lg {
    width: 46px;
  }

  .rbt-aux .rbt-close {
    margin-top: -4px;
    pointer-events: auto;
    /* Override pointer-events: none; above */
  }

  .has-aux .rbt-input {
    padding-right: 34px;
  }

  .rbt-highlight-text {
    background-color: inherit;
    color: inherit;
    font-weight: bold;
    padding: 0;
  }
}

/* Input Groups */
.input-group > .rbt {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.input-group > .rbt .rbt-input-hint-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.input-group > .rbt .rbt-aux,
.input-group > .rbt .rbt-input-hint {
  z-index: 5;
}

.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Validation States */
.has-error .rbt-input-multi.focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-warning .rbt-input-multi.focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-success .rbt-input-multi.focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

@keyframes loader-animation {
  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes loader-animation {
  to {
    -webkit-transform: rotate(1turn);
  }
}

// // // // // // // // // //

.rbt-input-multi.focus {
  background-color: #fff;
  border-color: $bright-purple;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  color: $bright-purple;
  outline: 0;
}

.rbt-input-multi.is-invalid.focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  -moz-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.rbt-input-multi.is-valid.focus {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  -moz-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.rbt-input-multi input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.rbt-input-multi input:-ms-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi input::-webkit-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi .rbt-input-main {
  height: auto;
}

.rbt-input-multi.form-control-lg .rbt-input-main {
  height: auto;
}

.rbt-input-multi.form-control-sm .rbt-input-main {
  height: auto;
}

.input-group > .rbt .rbt-input-hint-container {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
}

.dropdown-menu {
  box-shadow: 0px -10px 10px 0 rgba(0, 0, 0, 0.05);
  top: -6px !important;

  @include sscrollbar;
}
//////////////////
.dropdown-item {
  color: $slate-grey;

  small {
    color: darken($slate-grey, 5%);
  }

  &:hover,
  &:focus {
    background-color: $soft-purple !important;
    color: $bright-purple !important;

    small {
      color: darken($bright-purple, 25%);
    }
  }
}
