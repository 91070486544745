body {
  background-color: $body-bgcolor;
  // background-color: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 60%, #fff),
  //   linear-gradient(70deg, #dbedff 32%, #ebfff0);
  margin-bottom: $cf-footer-height;
  &.modal-open {
    padding-right: 0 !important;
  }
}
.main {
  background-color: white;
  border-radius: 2px;
  //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 0 1em;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0 15px;
  }
  *[class*='col-'],
  .card-body, .prescription-options {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0;
    }
  }
}
