.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  .logo {
    display: block;
    width: 100%;
    text-align: center;
    max-height: 100px;
    min-height: 75px;
    height: 100px;
    margin: 2em 0;
  }
  h2 {
    text-align: center;
    color: $slate-grey;
  }
  label {
    color: $slate-grey;
  }
  .btn.btn-primary {
    color: white;
    padding: 0.5em 1.5em;
    background-color: $primary;
  }
}
