.modal {
  // IE FALLBACK
  display: block;

  /* stylelint-disable */
  display: flex;
  /* stylelint-enable */

  // IE FALLBACK
  position: fixed;

  left: 0;
  right: 0;
  bottom: 0;

  margin: auto;

  &-dialog {
    margin: auto;

    &.loader {
      width: 100px;
      top: 50%;
      .modal-body {
        padding: 3em;
      }
    }
  }
  .terms-modal {
    .modal-header {
      .modal-title {
        font-size: $font-size-h1;
        color: $brand-blue;
      }
    }
    .modal-body {
      overflow-y: scroll;
      max-height: 600px;
      min-height: 200px;
      height: 100%;
      padding: 1.5em 1.5em 2.5em 1.5em;
      font-size: 0.9em;
    }
    .modal-footer {
      justify-content: space-between;

      .buttons {
        padding: 0;
        margin: 0;
        button {
          display: inline-block;
        }
        &:before {
          border-top: none;
        }
      }
    }
  }
  .exists-modal,
  .sure-modal,
  .ok-modal {
    width: 400px;
    top: 30%;
    .modal-header {
      .modal-title {
        font-size: $font-size-h1;
        color: $brand-blue;
      }
    }
    .modal-header,
    .modal-footer {
      border-top: none;
      border-bottom: none;
    }
    .modal-footer {
      justify-content: space-between;
    }
  }

  .sure-modal {
    .modal-footer {
      justify-content: space-evenly;
      button {
        width: 100px;
      }
    }
  }

  .reports-modal {
    width: 450px;
    top: 30%;
    .modal-header {
      .modal-title {
        font-size: $font-size-h1;
        color: $slate-grey;
      }
    }
    .modal-header,
    .modal-footer {
      border-top: none;
      border-bottom: none;
    }
    .modal-footer {
      justify-content: space-between;
    }
    .nav{
      margin: 0 15px;

      .nav-link {
        cursor: pointer;
      }
    }
  }

  .ok-modal {
    .modal-footer {
      justify-content: space-evenly;
    }
  }
}

.modal-header--no-border {
  border-bottom: none;
}
